<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
            <v-card tile>
                <v-form ref="form" @submit.prevent="login">
                    <v-toolbar color="primary" dark flat>
                        <v-toolbar-title v-t="'login.headline'" />
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field
                            v-model.trim.lazy="username"
                            :label="$t('login.username.label')"
                            :prepend-icon="svgUser"
                            :rules="usernameRules"
                            type="text"
                            name="username"
                        ></v-text-field>
                        <v-text-field
                            v-model.trim.lazy="password"
                            :label="$t('login.password.label')"
                            :prepend-icon="svgLock"
                            :rules="passwordRules"
                            type="password"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-t="'login.submit'"
                            :disabled="isLoggingIn"
                            :loading="isLoggingIn"
                            type="submit"
                            color="primary"
                            tile
                        />
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import { mdiAccount, mdiLock } from '@mdi/js'
import { extractAndDisplayError } from '@/utils'

export default {
    name: 'Login',
    data: () => ({
        svgUser: mdiAccount,
        svgLock: mdiLock,
        usernameRules: [],
        passwordRules: []
    }),
    computed: {
        username: {
            get() {
                return this.$store.state.auth.username
            },
            set(value) {
                this.$store.commit('auth/set', { key: 'username', value })
            }
        },
        password: {
            get() {
                return this.$store.state.auth.password
            },
            set(value) {
                this.$store.commit('auth/set', { key: 'password', value })
            }
        },
        ...mapState('auth', ['isLoggingIn'])
    },
    created() {
        this.usernameRules = [v => !!v || this.$t('login.username.error')]
        this.passwordRules = [v => !!v || this.$t('login.password.error')]
    },
    beforeDestroy() {
        // reset form on leave
        this.$store.commit('auth/set', { key: 'username', value: '' })
        this.$store.commit('auth/set', { key: 'password', value: '' })
    },
    methods: {
        login() {
            if (this.$refs.form.validate()) {
                this.$store
                    .dispatch('auth/login')
                    .then(() => {
                        this.$noty.success(this.$t('login.successMessage'), { timeout: 2000 })
                        const { redirect } = this.$route.query
                        const next = redirect ? redirect : { name: 'home' }
                        if (typeof next === 'string' && /^https?:\/\//.test(next)) {
                            window.location.href = next
                        } else {
                            this.$router.push(next)
                        }
                    })
                    .catch(err => extractAndDisplayError(err, this.$noty))
            }
        }
    }
}
</script>
